.imageContainer {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.image {
    height: 100%;
    user-select: none;
}

.addImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.3);
        transition: all 0.3s ease;
    }
}

.image_wr {

}

.image_title {
    font-size: 14px;
    color: #000000;
    margin-bottom: 10px;
}