@import "./fonts.scss";
@import "./constants.scss";
@import "./base.scss";
@import "./modal.scss";
@import "./reports.scss";

.calendar {
    background: #2D303E;
    border: 1px solid #393C49;
    border-radius: 8px;
    overflow: hidden;

    .rdrDayNumber span {
        color: #fff;
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        background: #EDA240;
        border: none;
    }

    .rdrDayNumber {
        z-index: 2;
    }

    .rdrMonthAndYearPickers select {
        color: #fff;
    }

    .rdrMonthAndYearPickers select {
        background: #EDA240;
        appearance: auto;
    }

    .rdrDateDisplayWrapper {
        background: #2D303E;
    }
}

*:disabled,
div:disabled {
    pointer-events: none;
    opacity: 0.6;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 0.6;
}

.times-div {
    font-size: 16px;
    font-weight: 500;
}