* {
  box-sizing: border-box;
}

.rdrCalendarWrapper {
  width: 100%;
}

html {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  // overflow-x: hidden;
}

body,
button,
ul,
ol,
h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-family: 'Inter', sans-serif;
}

body {
  background: #FAFAFA;
  min-width: 992px;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.absolute {
  position: absolute;
}

* {
  scrollbar-color: #eda240 #f0f0f0;
  scrollbar-width: thin;
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eda240;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eda240;
}