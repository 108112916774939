@import "../../../assets/styles/index.scss";

.wr_menu {

}

.ul {
    overflow: hidden;
    border-radius: 8px;
}

.li {

}

.a {
    padding: 10px;
    color: #fff;
    background: #9D9D9D;
    display: flex;
    align-items: center;
    transition: 0.4s;
    &:hover {
        background: #eda240;
    }
}

.active {
    background: #eda240;
}

.icon {
    margin-right: 10px;
    font-size: 0;
}