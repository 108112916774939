.wr_notifications {
    position: fixed;
    top: 80px;
    right: 50px;
    z-index: 1222200;
}

.item {
    padding: 10px;
    display: flex;
    align-items: center;
    color: #fff;
    width: 360px;
    border-radius: 8px;
    &:not(:first-child) {
        margin-top: 10px;
    }
}

.icon {
    width: 21px;
    height: 21px;
    color: #fff;
    display: flex;
    align-items: center;
}

.main {
    width: calc(100% - 42px);
    padding: 0 20px;
}

.btn {
    width: 21px;
    height: 21px;
    background: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    &:hover {
        transform: scale(1.05);
    }
}

.done {
    background: #54c854d9;
}

.warn {
    background: orange;
}

.error {
    background: red;
}

