@import "../../../assets/styles/index.scss";

$PLACEHOLDER_COLOR: #b8b8b8;

@mixin defaultFieldStyle {
    width: 100%;
    height: 73px;
    background: #ffffff;
    border-radius: 10px;
    border: 0;
    outline: 0;
    font-size: 1.125rem;
    padding: 0 30px;
    box-sizing: border-box;
    color: $TEXT_SECONDARY_DARK;
    border: 1px solid #DEDEDE;
    transition: all 0.2s ease;

    &::placeholder {
        color: $PLACEHOLDER_COLOR;
    }

    &:focus,
    &:hover {
        border: 1px solid $PRIMARY_ORANGE;
    }
}

@mixin defaultSecondaryFieldStyle {
    height: 42px;
    padding: 0 15px;
    font-size: 14px;
    background-color: #2d303e;
    color: $TEXT_PRIMARY_LIGHT;
    border: 1px solid transparent;

    &::placeholder {
        font-size: 14px;
    }
}

@mixin defaultErrorStyle {
    margin: 5px 0 10px;
    font-size: 0.8rem;
    transition: all 0.2s ease;
}

.input {
    width: 100%;
        margin-bottom: 15px;

    &__field-container {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

       & > svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &:first-of-type {
                left: 10px;
            }

            &:last-of-type {
                right: 10px;
            }
        }
    }

    label {
        margin-bottom: 10px;
        display: block;
        font-size: 14px;
        color: $TEXT_PRIMARY_DARK;

        span {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            color: #676767;
        }
    }

    &__field {
        @include defaultFieldStyle;

        &_error {
            @include defaultFieldStyle;
            border: 1px solid $RED;

            &_secondary {
                @include defaultSecondaryFieldStyle;
                border: 1px solid transparent;
            }
        }
    }

    &__error {
        @include defaultErrorStyle;
        color: transparent;
        transform: translateY(-5px);

        &_visible {
            @include defaultErrorStyle;
            color: $RED;
            transform: translateY(0);
        }
    }
}

.secondary {
    @include defaultSecondaryFieldStyle;
}

.input_secondary {
    label {
        color: #FFFFFF;
    }
}

.holder_btn {
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    font-size: 0;
}

.input_password {
    padding-left: 40px;
}
