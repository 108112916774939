.wr {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px dotted #EDA240;
}

.row {
    width: 100%;
    height: 10px;
    border-radius: 8px;
    border: 1px solid #eda240;
    overflow: hidden;
    position: relative;
}

.process {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #eda240;
    width: 0;
}
