@import "../../../assets/styles/index.scss";

@mixin defaultErrorStyle {
    margin: 5px 0 15px;
    font-size: 0.8rem;
    transition: all 0.2s ease;
}

.select {
    margin-bottom: 15px;
    width: 100%;
    position: relative;

    p {
        margin-bottom: 5px;
        display: block;
        font-size: 14px;
    }

    &__label {
        color: #fff;
    }

    &__error {
        @include defaultErrorStyle;
        color: transparent !important;
        transform: translateY(-5px);

        &_visible {
            @include defaultErrorStyle;
            color: red !important;
            transform: translateY(0);
        }
    }
}

.primary {
    p {
        color: $TEXT_PRIMARY_DARK;
    }
}

.load_wr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
