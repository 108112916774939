@import "../../../assets/styles/index.scss";

.wr_calendar {
    position: relative;
}

.main_cal {
    display: none;
}

.main_cal_active {
    display: block;
    position: relative;
}

.wr_in {
    position: relative;
    display: flex;
    align-items: flex-end;

    &>div {
        &:first-child {
            flex: 1 1 auto;
        }
    }
}

.bnt_close_cal {
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    background: red;
    color: #fff;
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    margin: 3px 0 3px 10px;
}

.main_cal_abs {
    position: fixed;
    left: 50%;
    margin-left: -180px;
    width: 360px;
    top: 50%;
    z-index: 100;
    margin-top: -180px;
}

.main_cal_opas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
    background: rgba(0, 0, 0, 0.8);
}

.wr_btn {
    margin-top: 10px;
}

.main_cal_box {
    box-shadow: 0px 2px 30px -12px #000;
}