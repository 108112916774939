.add_file_zone {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    border: 2px dotted #EDA240;
    padding: 10px;
    .presentation {
       height: 100%; 
    }
}

.bnt_zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
        font-size: 34px;
        color: #EDA240;
    }
    span {
        margin-top: 10px;
    }
}

.dragActive {
    border-color: green;
    svg {
        color: green;
    }
}