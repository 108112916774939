.wr {
    width: 100%;
    min-height: 100%;
    border: 2px dotted #eda240;
    padding: 10px;
}

.row {
    display: flex;
    align-items: center;
    padding-right: 40px;
    position: relative;
    &:not(:first-child) {
        margin-top: 10px;
    }
    font-size: 18px;
    svg {
        font-size: 32px;
    }
    span {
        padding-left: 10px;
    }
    button {
        position: absolute;
        top: 50%;
        right: 0px;
        height: 22px;
        width: 22px;
        margin-top: -10px;
        cursor: pointer;
        background: transparent;
        svg {
            width: inherit;
            height: inherit;
            color: red;
        }
        &:hover {
            svg {
                color: blue;
            }
        }
    }
}

.mt_30 {
    margin-top: 30px;
}
