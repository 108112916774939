.title_report {
    font-size: 20px;
    font-weight: 500;
}

.r-rows {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid #d1cecb;
}

.r-col {
    min-width: 200px;
    display: flex;
    align-items: center;
}

.btnse {
    margin-left: 14px;
}

.r-right-t {
    padding-left: 20px;
}

.r-left-t {
    flex: 1 1 auto;
}

.mt_2 {
    margin-top: 12px;
}

.col-t {
    margin-top: 6px;
    width: 480px;
    display: flex;
    align-items: center;
    gap: 14px;

    &-d {
        width: 200px;
        margin-top: -4px;
    }
}

.row_btns {
    display: flex;
    margin-left: 12px;

    button {
        margin-right: 14px;
    }
}

.row-me {
    display: flex;
    margin-top: 16px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.wr-table-cat {
    position: relative;
}

.menu-cat-r {
    width: 220px;
    background: #ebe4de;
}

.wr-table-cat {
    padding-right: 20px;
}

.lict-cat {
    max-height: calc(100vh - 271px);
    overflow-y: scroll;
}

.flex-catb {
    display: flex;
    flex-wrap: wrap;

    button {
        margin: 0 6px 6px 0;

        span {
            font-size: 13px;
        }
    }
}

.lict-cat__item {
    cursor: pointer;
    padding: 8px;

    div {
        font-weight: 500;
        color: #000;
    }

    span {
        color: grey;
        font-size: 13px;
        padding-top: 4px;
    }

    transition: 0.2s;

    &:not(:first-child) {
        border-bottom: 1px solid #d1cecb;
    }

    &:hover,
    &.active {
        background: #c7bebe;
    }
}

// tables
.tables-report {
    font-size: 14px;
    position: relative;
}

.report-wr-row {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: initial;
}

.report-table {
    border: 1px solid #d1cecb;
}

.item-col-table {
    padding: 4px 8px;
    min-width: 260px;
    max-width: 260px;
    border-right: 1px solid #d1cecb;
    position: relative;
    display: flex;

    // span {
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     max-width: 100%;
    //     display: block;
    // }

    .non-text-opas {
        opacity: 0;
    }
}

.report-head,
.report-row-table {
    display: flex;
}

.report-row-table {
    &:not(:first-child) {
        .item-col-table {
            border-top: 1px solid #d1cecb;
        }
    }
}

.report-head {
    padding-bottom: 3px;
    align-items: flex-end;

    .item-col-table {
        border: 1px solid #d1cecb;

        &:not(:last-child) {
            border-right: none;
        }
    }
}

.item-one {
    border-top: 1px solid #d1cecb;
    border-right: 1px solid #d1cecb;
    padding: 4px 8px;
}

// end tables

.empty-list {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
}

.head-col-row_two {
    &__top {
        padding: 4px 8px;
        border-width: 1px 1px 0 1px;
        border-style: solid;
        border-color: #d1cecb;
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__bot {
        display: flex;
    }


    .item-col-table {
        &:last-child {
            width: 200px;
        }
    }
}

.item-col-table_list {
    flex-direction: column;
    padding: 0;
}

.item-col-list {
    width: 100%;
    padding: 4px 8px;
    min-height: 26px;

    &:not(:first-child) {
        border-top: 1px solid #d1cecb;
    }
}

.sales-filter {
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #d1cecb;
    padding-bottom: 16px;
    flex-wrap: wrap;
}

.item-sales-filter {
    min-width: 150px;
}

.btn-wt-sales {
    padding-top: 20px;
    position: relative;
}

.item-sales-filter_range {
    margin-top: -4px;
}

.sales-wr-table {
    margin-top: 16px;

    table {
        tr {
            &:last-child {
                td {
                    font-weight: 700;
                }
            }
        }
    }
}

.text_info {
    text-align: center;
    padding: 20px;
}

.mb-not>div {
    margin-bottom: 0;
    margin-top: 0;
}

.wr-pagin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 30px;
    padding-bottom: 30px;

    button {
        padding: 4px 5px;
        border: 1px solid #E6E6E6;
        background: transparent;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;

        &.active,
        &:hover {
            background: #eda240;
            color: #fff;
        }
    }
}

.info-text-zvit {
    text-align: center;
    margin-top: 10px;
    color: #fff;
}

.modal-form-check {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;

    .opas {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.4);
        filter: blur(4px);
        z-index: 100;
        transition: 0.4s;
    }

    &__wr {
        right: 0;
        width: 400px;
        border-radius: 12px;
        padding: 16px;
        background: #fff;
        box-shadow: 1px 3px 12px -4px rgba(0, 0, 0, 0.5);
        z-index: 1000;
        position: relative;
    }
}

.wore-modal-col {
    display: grid;
    gap: 16px;
    margin-top: 6px;

    &_two {
        grid-template-columns: 25% 40% 25%;
    }
}

.btn-min-row {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
}

.modal-in-number {
    margin-top: 14px;
    display: flex;
    align-items: flex-end;
    gap: 16px;

    &>div {
        &:nth-child(2) {
            width: 150px;
        }

        &:nth-child(2) {
            width: 130px;
            margin-bottom: -3px;
        }
    }

    select,
    input {
        height: 36px;
    }

    select {
        padding: 6px;
    }

    button {
        padding-bottom: 7px;
    }

    &_two {
        margin-top: 5px;

        span {
            font-size: 0;
        }
    }
}

.in-grn {
    padding-bottom: 12px;
}

.dish {
    margin-top: 20px;

    span {
        color: #EDA240;
        font-weight: 600;
    }
}

.wore-modal-wr-btn {
    margin: 30px auto 0 auto;
    width: 140px;

    button {
        width: 100%;
    }
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.from-btn-add-price {
    margin-top: 16px;

    button {
        padding: 4px;
        border-radius: 6px;
        cursor: pointer;
    }
}

.head-form-modal {
    border-bottom: 1px solid #dedede;
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 16px;
    padding: 0 16px 10px 16px;
}

.content-check-wore {
    max-height: 240px;
    overflow-y: auto;
    margin: 20px 0 0 0;

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 13px;
    }

    table,
    th,
    td {
        border: 1px solid #DEDEDE;
    }
}

.title-table {
    padding-bottom: 3px;
    color: #EDA240;
}

.col-t-k {
    width: 200px;
}

.res-kit-wr {
    width: 100%;
    position: relative;

    &__in {
        width: 100%;
        padding: 10px;
        background-color: #FFF;
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        color: #252525;
        font-size: 14px;
        height: 42px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        cursor: default;

        &::before {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            background: url("../icons/icon-filter.png") center/contain no-repeat;
            top: 50%;
            right: 4px;
            margin-top: -7px;
            z-index: 2;
            opacity: 0.5;
        }

        &:active {
            border-color: #EDA240;
        }
    }

    .res-kit-modal {
        position: absolute;
        top: 100%;
        padding-top: 10px;
        width: 420px;
        z-index: 20;

        &__main {
            border-radius: 12px;
            background: #fff;
            box-shadow: 0 0 17px -5px rgba(0, 0, 0, 0.4);
            padding: 12px;

            &_flex {
                display: flex;
            }

            &_bot {
                display: flex;
                justify-content: flex-end;
                margin-top: 14px;
                padding-top: 14px;
                border-top: 1px solid #e3e3e3;
            }
        }

        &__l,
        &__r {
            width: 50%;

            &>p {
                font-size: 14px;
                color: #EDA240;
            }
        }

        &__l {
            border-right: 1px solid #e3e3e3;

            &>p {
                padding-right: 12px;
            }
        }


        &__w-lir {
            padding: 12px 12px 0 0;
            height: 100%;
        }

        &__r {
            &>p {
                padding-left: 12px;
            }

            .res-kit-modal__w-lir {
                padding: 12px 0 0 12px;
            }
        }
    }
}

.list-r-k {
    max-height: 200px;
    overflow-y: auto;
    height: 100%;

    li {
        p {
            font-size: 13px;
        }

        &:not(:first-child) {
            margin-top: 8px;
        }

        &.item_all {
            p {
                font-weight: 500;
            }
        }
    }
}

.technical-info {
    position: absolute;
    font-size: 12px;
    top: -10px;
    left: 150px;
    border: 1px solid #e3e3e3;
    padding: 4px 10px;
    border-radius: 12px;
}

.btn-fixed-right {
    display: flex;
    position: fixed;
    width: 240px;
    right: 40px;
    bottom: 40px;
    z-index: 200;
    gap: 14px;
}

.wr-block-content {
    display: none;
    margin-top: 14px;
}

.wr-block-title {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    svg {
        transition: 0.4s;
    }
}

.wr-block {
    &.open {
        .wr-block-content {
            display: block;
        }

        .wr-block-title {
            svg {
                transform: rotateX(180deg);
            }
        }
    }
}